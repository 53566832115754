import { configureStore, createSlice } from '@reduxjs/toolkit'

const reviewsSlice = createSlice({
  name: 'reviews',
  initialState: {},
  reducers: {
    setReviews: (state, action) => action.payload,
  },
});

const notesSlice = createSlice({
  name: 'notes',
  initialState: {},
  reducers: {
    setNotes: (state, action) => action.payload,
  },
})

const store = configureStore({
  reducer: {
    reviews: reviewsSlice.reducer,
    notes: notesSlice.reducer,
  },
})

export const { setReviews } = reviewsSlice.actions
export const { setNotes } = notesSlice.actions
export default store
