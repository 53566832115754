import * as React from 'react'

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {
    IconButton,
    Stack,
} from '@mui/material'

import CV from './cv.pdf';
import Page from './Page';

const About = (props) => {
  return (
    <div>
      <Page name={'about'}/>
      <Stack direction="row" justifyContent="center">
        <IconButton 
          aria-label="Linkedin" 
          onClick={() => window.open("https://www.linkedin.com/in/norman-ponte-476279104/", "_blank")} 
          color="inherit"
          edge="end"
        >
          <LinkedInIcon/>
        </IconButton>
        <IconButton 
          aria-label="GitHub"
          onClick={() => window.open("https://github.com/nponte", "_blank")} 
          color="inherit"
          edge="end"
        >
          <GitHubIcon/>
        </IconButton>
        <IconButton 
          aria-label="CV"
          onClick={() => window.open(CV)}
          color="inherit"
          edge="end"
        >
          <PictureAsPdfIcon/>
        </IconButton>
      </Stack>
    </div>
  );
}

export default About;
