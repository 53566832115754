import * as React from 'react'
import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'
import { Typography } from '@mui/material'
import { CodeBlock, dracula } from 'react-code-blocks'
import { useSelector } from 'react-redux';

const Page = (props) => {
  const { name } = props
  const { doc } = useParams()
  const f = doc || name
  const blocks = useSelector(state => state.notes[f]);

  return (
    <div>
    {blocks.map(({ type, text, language }, i) => (
      {
	      'heading_1': <Typography key={i} variant="h5">{text}</Typography>,
        'heading_3': <Typography variant="h6" key={i}>{text}</Typography>,
        'paragraph': <Typography variant="p" key={i}><p>{text}</p></Typography>,
        'code': <CodeBlock text={text} key={i} language={language} theme={dracula} showLineNumbers={false} customStyle={{ fontSize: '12px', margin: '10px' }}/>,
        'quote': <Box sx={{margin: '15px'}}><Typography variant="quote" key={i}>{text}</Typography></Box>
      }[type] || <div/>
    ))}
    </div>
  )
}

export default Page
