import React from 'react'
import { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import {
  Box,
  Container,
  Paper,
} from '@mui/material'
import { useDispatch } from 'react-redux';
import { setNotes, setReviews } from './store';
import Space from './Space'
import About from './About'
import Notes from './Notes'
import Page from './Page'
import Reviews from './Reviews'
import Navigation from './Navigation'

const pageTheme = createTheme({
  typography: {
    fontFamily: '"Menlo", "Menlo LG", monospace',
    logo: {
      fontFamily: '"Radar", "Menlo", "Menlo LG", monospace',
      flexGrow: 1,
      fontSize: '24px',
    },
    quote: {
      fontFamily: '"Radar", "Menlo", "Menlo LG", monospace',
      fontSize: '22px',
      color: 'rgb(218, 218, 219)',
      textTransform: 'uppercase',
    },
    p: {
      fontSize: '14px',
      color: 'rgb(196, 196, 197)',
    },
  },
  palette: {
    mode: 'dark',
  },
  components: {
    MuiButtonBase: { 
      defaultProps: {
          disableRipple: true,
      },
    },
    MuiLink: {
      defaultProps: {
        paddingLeft: '16px',
      },
    }
  },
})

const MainContent = () => {
    const location = useLocation()

    return (
      <>
        {location.pathname === '/' ? (
          <div></div>
        ) : (
          <Paper variant="outlined" sx={{ padding: '20px', margin: '20px' }}>
            <Routes>
              <Route path='/' element={<div/>} />
              <Route path='/about' element={<About/>} />
              <Route path='/notes' element={<Notes/>} />
              <Route path='/reviews' element={<Reviews/>} />
              <Route path='/:doc' element={<Page/>} />
            </Routes>
          </Paper>
        )}
      </>
    )
}

const fetchData = async (url) => {
  const response = await fetch(url)
  if (!response.ok) {
    return {}
  }
  return response.json()
};

const App = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
      const fetchNotesAndRelatedData = async () => {
        try {
          const notesData = await fetchData('/doc/notes')
          const notesPromises = notesData.map(async ({ id, date, name}) => {
            const detailData = await fetchData(`/doc/${id}`)
            return { id, detailData }
          })
          const notesDetails = await Promise.all(notesPromises)
          const notes = notesDetails.reduce((acc, { id, detailData }) => {
            acc[id] = detailData
            return acc
          }, {})
          const aboutData = await fetchData('/doc/about')
          notes['about'] = aboutData
          dispatch(setNotes(notes));
        } catch (error) {
          console.error("Error fetching notes and about:", error);
        }
      };
  
      const fetchReviewsData = async () => {
        try {
          const reviewsData = await fetchData('/doc/reviews');
          dispatch(setReviews(reviewsData));
        } catch (error) {
          console.error("Error fetching reviews:", error);
        }
      };
  
      fetchNotesAndRelatedData();
      fetchReviewsData();
    }, [dispatch]);

    return (
      <ThemeProvider theme={pageTheme}>
        <CssBaseline/>
        <Box
         sx={{
           position: 'fixed',
           zIndex: '-1',
           height: '100%',
           width: '100%',
           backgroundColor: 'black',
         }}>
          <Space/>
        </Box>
        <Router>
          <Navigation/>
          <Container maxWidth="md">
            <Routes>
              <Route path='*' element={<MainContent/>} />
            </Routes>
          </Container>
        </Router>
      </ThemeProvider>
    )
}

export default App
