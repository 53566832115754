import * as React from 'react'
import { useSelector } from 'react-redux'
import { 
  Grid, 
  Box,
  Typography
} from '@mui/material'


const Reviews = (props) => {
    const reviews = useSelector(state => state.reviews)

    return (
      <Grid>
        {reviews.map(({ id, name, score}) => (
	        <Box key={id} display="flex" justifyContent="space-between">
            <Typography>{name}</Typography>
            <Typography textAlign="right">{score}</Typography>
          </Box>
        ))}
      </Grid>
    )
}

export default Reviews
