import React from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import {
  Container,
  Typography,
  Link
} from '@mui/material'


const Navigation = () => {
  const location = useLocation();
  const isActive = (path) => location.pathname === path;

  return (
    <Container
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        lineHeight: '60px'
      }}
    >
        <Typography variant="logo">Norman Ponte</Typography>
        <Link
          component={RouterLink}
          to="/about"
          sx={{
            color: isActive('/about') ? 'white' : '#ECEDFEFF', // Highlight if active
            fontWeight: isActive('/about') ? 'bold' : 'normal', // Optional: make bold if active
          }}
          underline="hover"
        >
          About
        </Link>
        <Link
          component={RouterLink}
          to="/notes"
          sx={{
            color: isActive('/notes') ? 'white' : '#ECEDFEFF', // Highlight if active
            fontWeight: isActive('/notes') ? 'bold' : 'normal', // Optional: make bold if active
          }}
          underline="hover"
        >
          Notes
        </Link>
        <Link
          component={RouterLink}
          to="/reviews"
          sx={{
            color: isActive('/reviews') ? 'white' : '#ECEDFEFF', // Highlight if active
            fontWeight: isActive('/reviews') ? 'bold' : 'normal', // Optional: make bold if active
          }}
          underline="hover"
        >
          Reviews
        </Link>
    </Container>
  )
}

export default Navigation
