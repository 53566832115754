import * as React from 'react'
import { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'
import { 
  ListItem, 
  Grid,
  Typography,
} from '@mui/material'

const Notes = (props) => {
    const [notes, setNotes] = useState([])

    useEffect(() => {
      fetch(`/doc/notes`)
        .then((data) => data.json())
        .then((data) => setNotes(data))
    }, [])
    
    return (
      <Grid>
      {notes.map(({ id, name, date }) => (
        <ListItem key={id}>
          <Link to={`/${id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
            <Typography sx={{ fontSize: '18px' }}>{name}</Typography>
            <Typography sx={{ fontSize: '10px', color: 'grey' }}>{date}</Typography>
          </Link>
        </ListItem>
      ))}
      </Grid>
    )
}

export default Notes
